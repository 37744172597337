body, table, tr, td, th, div * {
  font-family: "Roboto", "游ゴシック体", YuGothic, "Yu Gothic Medium", sans-serif !important;
}

.bg_layout_content {
  background-color: #F6F8F6;
}
.ant-notification-notice {
  padding: 16px !important;
  width: 100% !important;
}
.ant-notification-notice-close {
  top: 15px !important;
  inset-inline-end: 10px !important;
}
.ant-notification-notice-message {
  margin-bottom: 0 !important;
}
.ant-notification-notice-wrapper {
  width: calc(100vw - 300px) !important;
  border-radius: 2px !important;
}
.ant-notification-topRight {
  right: -24px !important;
}
.ant-notification-notice-warning {
  background-color: #fffbe6;
  border: #ffe58f !important;
}
.ant-notification-notice-success {
  background-color: #f6ffed;
  border: #b7eb8f !important;
}
.ant-notification-notice-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7 !important;
}
.article-action .ant-dropdown-trigger .anticon {
  color: #3b6061;
  font-size: 20px !important;
}
.ant-form-item-label {
  color: #323842;
  font-weight: 700;
}
.ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 10px !important;
}
.ant-modal-title {
  font-size: 20px !important;
}
.address-input input,
.address-input .ant-select {
  margin-bottom: 15px !important;
}

.modal_confirm {
  .ant-modal-content {
    border-top: 10px #faad14 solid !important;
    padding: 0 !important;
    .ant-modal-confirm-body {
      padding: 16px;
      .ant-modal-confirm-paragraph {
        max-width: 100%;
      }
    }
    .ant-modal-confirm-btns {
      padding: 16px;
      text-align: center;
      .ant-btn-primary {
        background-color: #faad14;
        box-shadow: 0 2px 0 #ecd6aa;
        &:hover {
          background-color: #b17705;
        }
      }
    }
  }
}
.modal-warning {
  .ant-modal-content {
    padding: 20px 24px !important;
    .ant-modal-header {
      border-bottom: none !important;
      .ant-modal-title {
        font-size: 16px !important;
      }
    }
  }
}
.modal-timestamp {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none !important;
    }
    .ant-modal-body {
      padding: 20px 24px !important;
    }
  }
}

.ant-select-dropdown .ant-select-item {
  padding: 8px 12px !important;
}
.image-upload .anticon-delete {
  display: none;
  background-color: #fff;
  border-radius: 10px;
  padding: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
}
.ant-upload:hover .image-upload .anticon-delete {
  display: block;
}
.label-linkage {
  border: none;
  background-color: #efeeee;
  border-radius: 16px;
  padding: 0px 7px;
  font-size: 11px;
  z-index: 1;
  left: -4px;
  top: -4px;
}
.object-fit-cover {
  object-fit: cover;
}
